<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title Unsubscribe from WishWell notifications
  div.description Select which types of emails to unsubscribe to
  div.input_container
    input(v-model="email" type="text" placeholder="Type your email" ref="email")
  div.list
    div(v-for="(group,index) in groups")
      div.group( v-on:click="toggleSelected(group)" :title="group.name")
        img.checkbox(v-bind:src="checkSrc(group.selected)")
        div.info
          div.name {{ group.name }}
          div.description {{ group.description }}
  div.controls
    button.cta(v-on:click="unsubscribe" v-bind:class="{small_screen:isSmallScreen}") {{ buttonTitle }}
</template>

<script>
/* global gtag */
import { UNSUBSCRIBE_EMAIL_MUTATION } from '../graphql/mutations'
import { GET_UNSUBSCRIBE_GROUPS_QUERY } from '../graphql/queries'

import Header from '../components/Header'

import { isEmail } from '../lib/utils'

export default {
  name: 'UnsubscribeEmail',
  components: {
    Header,
  },
  watch: {
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    buttonTitle () {
      if (this.loading) { return "submitting..." }
      return "Unsubscribe"
    },
    selectedGroupIds () {
      if (!this.groups) return null
      return this.groups.filter((g)=> {
        return g.selected
      }).map((group) => {
        return group.id
      })
    },
    valid_email () {
      return isEmail(this.email)
    },
  },
  data () {
    return {
      email: null,
      loading: false,
      groups: [],
      isSmallScreen: false,
      selectedGroups: [],
    }
  },
  apollo: {
    groups () {
      return {
        // gql query
        query: GET_UNSUBSCRIBE_GROUPS_QUERY,
        // parameters
        variables () {
          return {
          }
        },
        update: data => {
          return data.unsubscribeGroups.map((g) => {
            g.selected = false
            return g
          })
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    checkSrc: function(selected) {
      return selected ? process.env.BASE_URL + "checkbutton_on.svg" : process.env.BASE_URL + "checkbutton_off.svg"
    },
    toggleSelected: function(group) {
      group.selected = !group.selected
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    unsubscribe: async function() {
      if (!this.email || this.email == "" || !this.valid_email) {
        this.$toast.error('Please enter a valid email')
        return
      }

      if (!this.selectedGroupIds || this.selectedGroupIds.length <= 0) {
        this.$toast.error('Please select at least one group')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: UNSUBSCRIBE_EMAIL_MUTATION,
        variables: {
          email: this.email,
          groupIds: this.selectedGroupIds
        },
      }).then((data) => {
        this.loading = false
        // console.log(data)

        const success = data.data.unsubscribeEmail.success
        const errors = data.data.unsubscribeEmail.errors

        if (success) {
          this.$toast.success("Successfully unsubscribed "+ this.email+"!")
        } else if (errors) {
          this.errorsToast(errors)
        } else {
          this.$toast.error("An error occurred. please try again")
        }
      }).catch((error) => {
        this.loading = false
        this.errorToast(error)
      })
    },
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/unsubscribe_email'})
  }
}
</script>

<style scoped>

.description {
  font-family: gibsonregular;
  font-size: 20px;
  color: #918F99;
}

.bold {
  font-weight: bold;
}

.controls {
  margin: 20px 0px;
}

button {
  margin: 0px 20px 20px 0px;
}

button.cta {
  font-size: 20px;
}

button.small_screen {
  width: 90%;
  margin: 0px 0px 20px 0px;
}

.list {
  border: 1px solid #F5F5F6;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

  font-family: gibsonregular;
  font-size: 18px;
}

.label {
  font-size: 18px;
}

.name {
  font-family: gibsonsemibold;
  margin-bottom: 8px;
}

.active .name {
  color: #000000;
}

.checkbox {
  width: 40px;
  float: left;
}
.info {
  margin-left: 60px;
}

a {
  color: #6f63bd;
}

.group {
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #F5F5F6;
  background-color: #f8f8f8;
}

.list .group {
  background-color: #ffffff;
}

.list .group:hover {
  background-color: #f8f8f8;
}


</style>
